<template>
  <v-card
    class="pa-6 elevation-0 mt-4"
    min-width="300px"
    max-width="450px"
    width="450px"
  >
    <v-card-title class="subtitle-1 nearblack--text font-weight-bold">
      Favorites
    </v-card-title>

    <v-card-subtitle>
      Your reports in one place.
    </v-card-subtitle>

    <v-row class="pb-3">
      <v-col>
        <v-list
          v-for="(network, networkIndex) in networks"
          :key="networkIndex"
          class="py-0"
          dense
        >
          <v-list-item-group
            v-for="(team, teamIndex) in network.teams"
            :key="`${networkIndex}-${teamIndex}`"
          >
            <v-list-item
              v-for="(availableReport, availableReportIndex) in getAvailableReports(team)"
              :key="`${networkIndex}-${teamIndex}-${availableReportIndex}`"
              :href="makeHref(availableReport, team)"
              target="_self"
            >
              <v-list-item-title>
                {{ getTeamName(team) }} {{ titlecase(availableReport) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import PATHS from 'src/router/paths'
import format from 'src/utils/format'

const { mapGetters, mapState } = createNamespacedHelpers('auth')

export default {
  name: 'NetworkList',
  computed: {
    ...mapGetters(['isInternalUser', 'network']),
    ...mapState({
      user: (state) => state.user,
    }),
    networks() {
      const { network: networksArray } = this
      return networksArray.map((network) => network.publisher)
    },
    visibleReports() {
      return this.networks.reduce((visibleReports, item) => {
        const { teams } = item

        teams.forEach((team) => {
          const reports = this.getAvailableReports(team)

          visibleReports.push(...reports)
        })

        return visibleReports
      }, [])
    },
  },
  methods: {
    titlecase(value) {
      return format.titlecase(value)
    },
    goToDocs() {
      this.$router.push('/docs/releases')
      this.dismissFeaturePrompt()
    },
    async dismissFeaturePrompt() {
      this.dismissed = true

      const { user } = this
      const { email, personEmail, settings = {} } = user
      const alert = { dismissed: true }
      const doc = { settings: { ...settings, alert } }
      const docId = email || personEmail
      const data = { doc, docId }

      return this.$store.dispatch('auth/updateUserSettings', data)
    },
    openLink(url) {
      const target = '_blank'

      window.open(url, target)
    },
    getAvailableReports(team) {
      const reports = [PATHS.REPORTS.PACING]

      if (team.hasDiscrepancy) reports.push(PATHS.REPORTS.DISCREPANCY)
      if (team.hasHealthAdx) reports.concat([PATHS.REPORTS.ADX_HEALTH])
      if (team.hasHealthNetwork) reports.concat([PATHS.REPORTS.NETWORK_HEALTH])
      if (team.hasYieldPartner) reports.concat([PATHS.REPORTS.YIELD_PARTNER])

      return reports
    },
    getTeamName(team) {
      const { isAllAccess, orgName, text } = team
      return isAllAccess ? orgName : text
    },
    makeHref(reportName, team) {
      return `/reports/${reportName}/${team.reportId}${team.subId ? `/${team.subId}` : ''}`
    },
  },
}
</script>
